import {
    Button,
    Card,
    Chip,
    Container,
    Stack,
    Typography,
} from '@krakentech/coral';
import { GreenJobDetails } from '@/hooks/greenJobs/types';

export const GreenJobCard = ({ job }: { job: GreenJobDetails }) => {
    return (
        <>
            <Card theme="light" padding="small" md={{ padding: 'medium' }}>
                <Stack direction="vertical" gap="md">
                    <Stack direction="vertical" gap="xs">
                        <Typography variant="h3" color="dark">
                            {job.job_title} {job.company && `at ${job.company}`}
                        </Typography>
                        <Stack gap="sm" flexWrap="wrap">
                            {job.salary_range && (
                                <Container>
                                    <Chip color="secondary">
                                        {job.salary_range}
                                    </Chip>
                                </Container>
                            )}
                            {job.job_tags &&
                                job.job_tags.map((tag, index) => (
                                    <Container key={index}>
                                        <Chip variant="outlined">{tag}</Chip>
                                    </Container>
                                ))}
                        </Stack>
                    </Stack>
                    <Typography variant="h4" color="dark">
                        {job.description}
                    </Typography>
                    <Button href={job.link.url} color="secondary">
                        Find out more
                    </Button>
                </Stack>
            </Card>
        </>
    );
};
