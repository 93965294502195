import { Fragment, useState } from 'react';
import { Container, Dialog, Stack, Typography } from '@krakentech/coral';
import { UkMap } from '../assets/ukMap';
import { RegionsOptions } from '../types';
import { regionsText } from '../consts';
import { StyledGreenJobsMap } from './greenJobsMap.styled';
import { GreenJobCard } from '@/components/GreenJobCard';
import { useGreenJobs } from '@/hooks/greenJobs/greenJobs';
import { isProductionDeployment } from '@/utils/environment';

export const GreenJobsMap = () => {
    const [selectedRegion, setSelectedRegion] = useState<RegionsOptions | null>(
        null
    );
    const { data: allGreenJobs } = useGreenJobs(!isProductionDeployment);

    const greenJobsFilteredByRegion =
        (selectedRegion &&
            allGreenJobs?.filter(({ region }) =>
                region.includes(selectedRegion)
            )) ||
        null;

    return (
        <>
            <Container maxHeight={850} lg={{ paddingX: 'xl' }}>
                <StyledGreenJobsMap>
                    <UkMap handleRegionClick={setSelectedRegion} />
                </StyledGreenJobsMap>
            </Container>
            <Dialog
                ariaLabelledBy="List of jobs based on region"
                open={selectedRegion !== null}
                onClose={() => setSelectedRegion(null)}
                maxWidth="full"
            >
                <Stack direction="vertical" gap="lg">
                    {selectedRegion && (
                        <Typography variant="h2">
                            <strong>
                                Jobs in {regionsText[selectedRegion]}
                            </strong>
                        </Typography>
                    )}
                    <Container paddingY="sm">
                        <Stack direction="vertical" gap="lg">
                            {greenJobsFilteredByRegion?.length ? (
                                greenJobsFilteredByRegion.map((job, index) => (
                                    <Fragment key={index}>
                                        {<GreenJobCard job={job} />}
                                    </Fragment>
                                ))
                            ) : (
                                <Typography variant="h4">
                                    There are currently no jobs available in
                                    this region
                                </Typography>
                            )}
                        </Stack>
                    </Container>
                </Stack>
            </Dialog>
        </>
    );
};
