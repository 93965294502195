import { Typography, Button, Stack } from '@krakentech/coral';
import { Footer as CoralFooter } from '@krakentech/coral-organisms';
import { mainLinkMenu, subLinkMenu } from './content';

const Footer = () => (
    <CoralFooter>
        <CoralFooter.ListContainer variant="left">
            <CoralFooter.List>
                <Stack flexWrap="wrap">
                    {mainLinkMenu.map((menuItem) => (
                        <CoralFooter.ListItem key={menuItem.text}>
                            <Typography variant="body1" component="span">
                                <Button
                                    variant="link"
                                    color="secondary"
                                    href={menuItem.href}
                                >
                                    {menuItem.text}
                                </Button>
                            </Typography>
                        </CoralFooter.ListItem>
                    ))}

                    {subLinkMenu.map((menuItem) => (
                        <CoralFooter.ListItem key={menuItem.text}>
                            <Typography variant="body1" component="span">
                                <Button
                                    variant="link"
                                    color="secondary"
                                    href={menuItem.href}
                                >
                                    {menuItem.text}
                                </Button>
                            </Typography>
                        </CoralFooter.ListItem>
                    ))}
                </Stack>
            </CoralFooter.List>
        </CoralFooter.ListContainer>
        <CoralFooter.Text>
            <Typography variant="caption">
                Octopus Energy Ltd is a company registered in England and Wales.
            </Typography>
            <Typography variant="caption">
                Registered number&#58; 09263424. Registered office&#58; UK
                House, 5th floor&#44; 164-182 Oxford Street&#44; London&#44; W1D
                1NN.
            </Typography>
        </CoralFooter.Text>
    </CoralFooter>
);

export default Footer;
