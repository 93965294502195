import { storyblokEditable } from '@storyblok/react';
import { Container } from '@krakentech/coral';
import { GreenJobsMap } from './components/greenJobsMap';
import { GreenJobsMapStoryblok } from '@/types/generated/storyblok/schema';

type DynamicGreenJobsMapStoryblok = {
    blok: GreenJobsMapStoryblok;
};

const DynamicGreenJobsMap = ({ blok }: DynamicGreenJobsMapStoryblok) => {
    return (
        <Container paddingBottom="xxxl" {...storyblokEditable(blok)}>
            <GreenJobsMap />
        </Container>
    );
};

export default DynamicGreenJobsMap;
