import { useQuery } from '@tanstack/react-query';
import getAllGreenJobs from './getAllGreenJobs.graphql';
import { GQLGreenJobsResponse, GreenJobItem, GreenJobDetails } from './types';
import { storyblokGraphQLClient } from '@/utils/storyblok';
import getErrorMessage from '@/utils/errors';

export const fetchGreenJobs = async (
    preview: boolean
): Promise<GreenJobItem[]> => {
    try {
        const { GreenjobItems }: GQLGreenJobsResponse =
            await storyblokGraphQLClient(preview).request(getAllGreenJobs);
        return GreenjobItems.items;
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        console.error({ message: errorMessage });
        throw new Error(errorMessage);
    }
};

const transformGreenJobsList = (data: GreenJobItem[]): GreenJobDetails[] =>
    data.map((greenJob) => ({
        company: greenJob.content.company,
        job_title: greenJob.content.job_title,
        job_tags: greenJob.content.job_tags,
        link: greenJob.content.link,
        region: greenJob.content.region,
        description: greenJob.content.description,
        salary_range: greenJob.content.salary_range,
        job_type: greenJob.content.job_type,
    }));

export const useGreenJobs = (preview: boolean) =>
    useQuery(['green-jobs'], () => fetchGreenJobs(preview), {
        select: transformGreenJobsList,
    });
