import { useState } from 'react';
import { storyblokEditable } from '@storyblok/react';
import { Container, Select, Stack } from '@krakentech/coral';
import { GreenJobsListStoryblok } from '@/types/generated/storyblok/schema';
import { GreenJobCard } from '@/components/GreenJobCard';
import { useGreenJobs } from '@/hooks/greenJobs/greenJobs';
import { isProductionDeployment } from '@/utils/environment';

type DynamicGreenJobsListStoryblok = {
    blok: GreenJobsListStoryblok;
};

const DynamicGreenJobsList = ({ blok }: DynamicGreenJobsListStoryblok) => {
    const [activeJobType, setActiveJobType] = useState('all');
    const { data: allGreenJobs } = useGreenJobs(!isProductionDeployment);

    return (
        <Container paddingBottom="xxxl" {...storyblokEditable(blok)}>
            <Stack direction="vertical" gap="lg">
                <Select
                    label="Job type"
                    defaultValue={{ value: 'all', label: 'All opportunities' }}
                    values={[
                        { value: 'all', label: 'All opportunities' },
                        { value: 'job', label: 'Jobs' },
                        { value: 'training', label: 'Training' },
                    ]}
                    onChange={(e) =>
                        typeof e?.value === 'string' &&
                        setActiveJobType(e.value)
                    }
                />
                <Stack direction="vertical">
                    {allGreenJobs
                        ?.filter((job) => {
                            if (activeJobType === 'all') {
                                return job;
                            } else {
                                return job.job_type === activeJobType;
                            }
                        })
                        ?.map((job, index) => (
                            <GreenJobCard key={index} job={job} />
                        ))}
                </Stack>
            </Stack>
        </Container>
    );
};

export default DynamicGreenJobsList;
